<template>
  <base-layout>
    <ion-content id="subpage-detail" class="has-header has-subheader has-bottom-banner">
      <h4 class="item" v-html="abstract.title"
          :style="{'font-size' : fontsize + 'rem','line-height' : fontsize *1.4 + 'rem'}"></h4>

      <div v-html="abstract.text" :style="{'font-size' : fontsize + 'rem','line-height' : fontsize *1.4 + 'rem'}"></div>
    </ion-content>
    <div style="display: flex; justify-content: center">
      <div class="btn-group" role="groupFont" aria-label="Basic radio toggle button group" style="width: 100%;">
        <input type="checkbox" class="btn-check" name="btnradio" id="btnradio08" autocomplete="off"
               @click="changeFontSize(0.8)" :checked="fontsize == 0.8">
        <label class="btn btn-outline-primary" for="btnradio08">-</label>

        <input type="checkbox" class="btn-check" name="btnradio" id="btnradio10" autocomplete="off"
               @click="changeFontSize(1)" :checked="fontsize == 1">
        <label class="btn btn-outline-primary" for="btnradio10">A</label>

        <input type="checkbox" class="btn-check" name="btnradio" id="btnradio12" autocomplete="off"
               @click="changeFontSize(1.2)" :checked="fontsize == 1.2">
        <label class="btn btn-outline-primary" for="btnradio12">+</label>
      </div>
    </div>

  </base-layout>
</template>

<script>
import {IonContent} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions} from "vuex";
import moment from "moment";

export default defineComponent({
  name: "AbstractDetail",
  data() {
    return {
      abstract: {},
      fontsize: 1
    };
  },
  components: {
    IonContent,
  },
  methods: {
    ...mapActions("abstracts", [
      "getAbstract",
    ]),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    changeFontSize(size) {
      console.log('FONT Changed')
      this.fontsize = size;
    }
  },

  async created() {
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(route) {
        if (route.name == 'Abstract Detail') {
          this.moment = moment;
          this.abstract = await this.getAbstract(this.$route.params.id);
          this.trackWithLabel(this.abstract.title)

        }
      }
    }
  }
});
</script>
<style scoped>
#subpage-detail {
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
  --padding-bottom: 10px;
}

</style>
